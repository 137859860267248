import * as React from "react"
import { Checkbox } from "antd"
import { CheckboxProps } from "antd/es/checkbox"
import "./DomeCheckbox.scss"

const DomeCheckbox: React.FC<CheckboxProps> = (props) => {
  return (
    <div className={"dome-checkbox"}>
      <Checkbox {...props} name={props.name}>
        {props.children}
      </Checkbox>
    </div>
  )
}

export default DomeCheckbox
