import React from "react"
import { TextTypography } from "../../SharedResources/Typography/TextTypography/TextTypography"
import CurveContainer from "../../CurveContainer/CurveContainer"
import {
  FontStyle,
  TextColor,
} from "../../SharedResources/Typography/typography.service"
import AcademicsSignUpForm from "./AcademicsSignUpForm"

const AcademicsSignUp: React.FC = () => {
  return (
    <section>
      <div className="mt-16">
        <CurveContainer color="primary">
          <div className="flex flex-col max-w-4xl mx-auto px-8 pb-10">
            <TextTypography
              className="text-4xl md:text-5xl"
              color={TextColor.WHITE}
              fontStyle={FontStyle.SEMIBOLD}
            >
              Get Involved
            </TextTypography>
            <TextTypography
              className="text-3xl md:text-4xl mt-6"
              color={TextColor.WHITE}
              fontStyle={FontStyle.ITALIC}
            >
              Academics have policy expertise. The decision-makers need that.
              They have questions and you have answers.
            </TextTypography>

            <TextTypography
              className="text-3xl md:text-4xl mt-6"
              color={TextColor.WHITE}
              fontStyle={FontStyle.ITALIC}
            >
              Dome makes it easy to engage with your most powerful audience.
            </TextTypography>
          </div>
        </CurveContainer>
        <div className="flex items-center justify-center -mt-40 p-8 md:p-0">
          <div className="bg-white p-6 md:p-8 rounded-xl border-2 max-w-md z-40 mb-16">
            <AcademicsSignUpForm />
          </div>
        </div>
      </div>
    </section>
  )
}

export default AcademicsSignUp
