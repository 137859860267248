import React, { useState } from "react"
import { TextTypography } from "../../SharedResources/Typography/TextTypography/TextTypography"
import {
  FontStyle,
  TextColor,
} from "../../SharedResources/Typography/typography.service"
import DomeButton from "../../SharedResources/Button/Button"
import { Link } from "gatsby"
import { Formik } from "formik"
import { DomeSpinner } from "../../SharedResources/DomeSpinner/DomeSpinner"
import DomeCheckbox from "../../SharedResources/DomeCheckbox/DomeCheckbox"
import TextField from "../../SharedResources/TextField/TextField"
import * as Yup from "yup"
import { academicSignUp } from "../../../services/api.service"
import { displayNotification } from "../../../services/toast.service"

const AcademicsSignUpForm: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const signUpFormSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    organization: Yup.string().required("Organization is required"),
    email: Yup.string().required("Email is required").email("Invalid Email"),
    password: Yup.string()
      .min(10, "Password must be at least 10 characters")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/\d/, "Password must contain at least one digit")
      .required("Password is required"),

    pledgeOfCivility: Yup.boolean().required().oneOf([true]),
    termsOfService: Yup.boolean().required().oneOf([true]),
  })

  const handleSubmit = async (values) => {
    const firstName = values.firstName
    const lastName = values.lastName
    const organization = values.organization
    const email = values.email
    const password = values.password
    await academicSignUp({
      first_name: firstName,
      last_name: lastName,
      organization: organization,
      department: "Department",
      email: email,
      password: password,
    })
      .then(() => {
        displayNotification("Your Account has been created!!")
        setIsSubmitting(true)
      })
      .catch((error) => {
        displayNotification(error?.response?.data?.message, true)
        setIsSubmitting(false)
      })
  }
  return !isSubmitting ? (
    <div>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          organization: "",
          email: "",
          password: "",
          pledgeOfCivility: false,
          termsOfService: false,
        }}
        onSubmit={handleSubmit}
        validationSchema={signUpFormSchema}
      >
        {(formProps) => (
          <form
            className="space-y-6"
            onSubmit={formProps.handleSubmit}
            onReset={formProps.handleReset}
          >
            <TextField
              isRequired
              name={"firstName"}
              label="First Name"
              onChange={formProps.handleChange}
              onBlur={formProps.handleBlur}
              error={formProps.touched.firstName && formProps.errors.firstName}
              containerClassName={"mb-2"}
              placeholder={"First Name"}
            />
            <TextField
              name={"lastName"}
              label="Last Name"
              onChange={formProps.handleChange}
              onBlur={formProps.handleBlur}
              error={formProps.touched.lastName && formProps.errors.lastName}
              containerClassName={"mb-2"}
              placeholder={"Last Name"}
            />
            <TextField
              isRequired
              name={"organization"}
              label="University, think tank, or foundation"
              onChange={formProps.handleChange}
              onBlur={formProps.handleBlur}
              error={
                formProps.touched.organization && formProps.errors.organization
              }
              containerClassName={"mb-2"}
              placeholder={"Organization"}
            />
            <TextField
              isRequired
              name={"email"}
              label="Email"
              onChange={formProps.handleChange}
              onBlur={formProps.handleBlur}
              containerClassName={"mb-2"}
              error={formProps.touched.email && formProps.errors.email}
              placeholder={"Email"}
            />
            <TextField
              isRequired
              name={"password"}
              label="Password"
              type="password"
              onChange={formProps.handleChange}
              onBlur={formProps.handleBlur}
              containerClassName={"mb-2"}
              error={formProps.touched.password && formProps.errors.password}
              placeholder={"Password"}
            />
            <div className="flex space-x-2">
              <DomeCheckbox
                name="pledgeOfCivility"
                onChange={formProps.handleChange}
              />
              <TextTypography>
                Inside Dome, I pledge to not attack another person, to not
                impugn another person’s motives, and to not use toxic or vulgar
                expressions. I accept the
                <Link to="/pledge-of-civility"> Dome Pledge of Civility</Link>
              </TextTypography>
            </div>
            <div className="flex space-x-2">
              <DomeCheckbox
                onChange={formProps.handleChange}
                name="termsOfService"
              />
              <TextTypography>
                I Accept the{" "}
                <Link to="/terms-of-service">terms of service</Link>
              </TextTypography>
            </div>
            <DomeButton
              htmlType={"submit"}
              disabled={
                formProps.isSubmitting || !formProps.dirty || !formProps.isValid
              }
              type={"primary"}
              isFullWidthButton={true}
            >
              {formProps.isSubmitting ? (
                <DomeSpinner className={"white-spinner-color"} size={"small"} />
              ) : (
                "Sign Up"
              )}
            </DomeButton>
            <div className="mt-3 flex justify-end">
              <Link to="/sign-in">
                <TextTypography color={TextColor.SECONDARY}>
                  Already have an account?
                </TextTypography>
              </Link>
            </div>
          </form>
        )}
      </Formik>
    </div>
  ) : (
    <div className="flex flex-col space-y-8 pb-96">
      <TextTypography fontStyle={FontStyle.BOLD} className="text-4xl">
        Confirmed
      </TextTypography>
      <div className="flex flex-col">
        <TextTypography className="text-sm">
          You have signed up as an Academics on Dome Events.
        </TextTypography>
        <TextTypography>
          We will confirm your information shortly.
        </TextTypography>
      </div>
    </div>
  )
}

export default AcademicsSignUpForm
