import React from "react"
import "./CurveContainer.scss"

interface CurveContainerProps {
  children: JSX.Element
  color?: "light" | "primary"
  hideBottomCurve?: boolean
}

const CurveContainer: React.FC<CurveContainerProps> = ({
  children,
  color = "primary",
  hideBottomCurve = false,
}) => {
  return (
    <div className="w-full relative curve-container">
      <div className={`top-curve-${color} relative z-10`} />
      <div
        className={`relative z-10 -mt-28 ${
          color === "primary" ? "bg-primary" : "bg-lightBackground"
        } `}
      >
        {children}
      </div>
      <div
        className={`${!hideBottomCurve && `bottom-curve-${color}`} relative`}
      />
    </div>
  )
}

export default CurveContainer
