import React from "react"
import AppFooter from "../components/AppFooter/AppFooter"
import AppHeader from "../components/V2/AppHeader"
import FaviconHeader from "../components/FaviconHeader/FaviconHeader"
import AcademicsSignUp from "../components/SignUp/AcademicsSignUp/AcademicsSignUp"

const AboutPage: React.FC = () => {
  return (
    <main className="w-full bg-lightBackground">
      <FaviconHeader />
      <AppHeader />
      <AcademicsSignUp />
      <AppFooter />
    </main>
  )
}

export default AboutPage
